import React from "react";
import { NavLink } from "@remix-run/react";
import { Heading, Flex, IconButton, Text } from "@radix-ui/themes";
import { ChevronLeftIcon } from "@radix-ui/react-icons";

type TitleBodyContainerProps = LeveledTitleBodyContainerProps & {
  level?: 1 | 2;
};
type LeveledTitleBodyContainerProps = {
  title: string;
  description?: string;
  backTo?: string;
  action?: React.ReactNode;
  children: React.ReactNode;
};
export const TitleBodyContainer: React.FC<TitleBodyContainerProps> = (
  props: TitleBodyContainerProps
) => {
  const { level = 2, children, ...rest } = props;
  switch (level) {
    case 1:
      return <TitleBodyContainerL1 {...rest}>{children}</TitleBodyContainerL1>;
    case 2:
      return <TitleBodyContainerL2 {...rest}>{children}</TitleBodyContainerL2>;
    default:
      return <TitleBodyContainerL2 {...rest}>{children}</TitleBodyContainerL2>;
  }
};
TitleBodyContainer.displayName = "TitleBodyContainer";

const TitleBodyContainerL1: React.FC<LeveledTitleBodyContainerProps> = ({
  title,
  description,
  backTo,
  action,
  children,
}: LeveledTitleBodyContainerProps) => {
  return (
    <Flex direction="column" gap="4">
      <div className="flex justify-between">
        <Flex align="center" gap="2">
          {backTo && (
            <IconButton type="button" variant="soft" color="gray">
              <NavLink to={backTo}>
                <ChevronLeftIcon />
              </NavLink>
            </IconButton>
          )}
          <Flex direction="column" gap="1">
            <Heading as="h1" size="5">
              {title}
            </Heading>
            {description && <Text size="2">{description}</Text>}
          </Flex>
        </Flex>
        {action && <div className="flex gap-4">{action}</div>}
      </div>
      <div className="w-full">{children}</div>
    </Flex>
  );
};

const TitleBodyContainerL2: React.FC<LeveledTitleBodyContainerProps> = ({
  title,
  description,
  backTo,
  action,
  children,
}: LeveledTitleBodyContainerProps) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <Flex align="center" gap="2">
          {backTo && (
            <IconButton type="button" variant="soft" color="gray" size="1">
              <NavLink to={backTo}>
                <ChevronLeftIcon />
              </NavLink>
            </IconButton>
          )}

          <Flex direction="column" gap="2">
            <Heading as="h2" size="4">
              {title}
            </Heading>
            {description && <Text size="2">{description}</Text>}
          </Flex>
        </Flex>
        {action && <div className="flex gap-4">{action}</div>}
      </div>
      <div className="w-full">{children}</div>
    </div>
  );
};
